import axios from 'axios';
import { BETTING_RULES_URL, whiteListedCountriesMinimalCasinoContent } from '../constants';
import { COOKIES_DAYS_TO_LIVE } from '../data-types/cookies/cookiesConstants';
import { CookieType } from '../data-types/cookies/cookiesEnums';
import { AcceptedCountries, CURRENCY_MULTIPLIER } from '../enums';
import { setUserGeoLocation } from '../state/actions';
import { store } from '../state/store';
import { computeExpirationDate, setCookie } from './cookies';
import { generateSignUpUrl } from './url';

export const getGeoLocation = async () => {
  await axios
    .get('https://ipapi.co/json/')
    .then(response => {
      const data = response.data;
      setCookie(CookieType.COUNTRY, data.country_code, computeExpirationDate(COOKIES_DAYS_TO_LIVE));
      setUserGeoLocation(data.country_code);
    })
    .catch(() => {
      setUserGeoLocation(AcceptedCountries.UNITED_KINGDOM);
    });
};

export const getCurrencyValue = (amount: number) => {
  const { geoLocation } = store.getState();

  switch (geoLocation) {
    case AcceptedCountries.INDIA:
      return amount * CURRENCY_MULTIPLIER.INDIA;
    case AcceptedCountries.BRAZIL:
      return amount * CURRENCY_MULTIPLIER.BRAZIL;
    case AcceptedCountries.NEW_ZEALAND:
      return amount * CURRENCY_MULTIPLIER.NEW_ZEALAND;
    case AcceptedCountries.UNITED_KINGDOM:
    case AcceptedCountries.IRELAND:
    case AcceptedCountries.GERMANY:
    case AcceptedCountries.FINLAND:
    default:
      return amount;
  }
};

export const displayMinimalContent = () => {
  const { geoLocation } = store.getState();

  return whiteListedCountriesMinimalCasinoContent.includes(geoLocation);
};

export const getSignUpUrl = () => {
  const { geoLocation, isBetkwiffDomain, isCasinoDomain } = store.getState();

  if (isBetkwiffDomain && !isCasinoDomain) {
    switch (geoLocation) {
      case AcceptedCountries.FINLAND:
      case AcceptedCountries.NEW_ZEALAND:
      case AcceptedCountries.CANADA:
        return generateSignUpUrl('/sports/live-now');
      default:
        return generateSignUpUrl('/sports/11/24hrs');
    }
  }

  return generateSignUpUrl();
};

export const getTermsLinkByCountry = () => {
  const { geoLocation } = store.getState();

  switch (geoLocation) {
    case AcceptedCountries.UNITED_KINGDOM:
    case AcceptedCountries.IRELAND:
      return `https://corporate.kwiff.com/terms-conditions/`;
    case AcceptedCountries.UGANDA:
      return `https://corporate.kwiff.com/terms-conditions-ug/`;
    case AcceptedCountries.KENYA:
      return `https://corporate.kwiff.com/terms-conditions-ke/`;
    case AcceptedCountries.BRAZIL:
      return `https://corporate.kwiff.com/terms-conditions-iom-brpt/`;
    case AcceptedCountries.MEXICO:
    case AcceptedCountries.COLOMBIA:
    case AcceptedCountries.CHILE:
    case AcceptedCountries.PERU:
    case AcceptedCountries.ARGENTINA:
    case AcceptedCountries.ECUADOR:
    case AcceptedCountries.EL_SALVADOR:
    case AcceptedCountries.PARAGUAY:
    case AcceptedCountries.URUGUAY:
      return 'https://corporate.kwiff.com/terms-conditions-iom-es';
    default:
      return `https://corporate.kwiff.com/terms-conditions-iom/`;
  }
};

export const getBettingRulesLinkByCountry = () => {
  const { geoLocation } = store.getState();

  switch (geoLocation) {
    case AcceptedCountries.BRAZIL:
      return 'https://corporate.kwiff.com/betting-rules-brpt/';
    case AcceptedCountries.MEXICO:
    case AcceptedCountries.COLOMBIA:
    case AcceptedCountries.CHILE:
    case AcceptedCountries.PERU:
    case AcceptedCountries.ARGENTINA:
    case AcceptedCountries.ECUADOR:
    case AcceptedCountries.EL_SALVADOR:
    case AcceptedCountries.PARAGUAY:
    case AcceptedCountries.URUGUAY:
      return 'https://corporate.kwiff.com/betting-rules-es/';
    default:
      return BETTING_RULES_URL;
  }
};
