import { default as Cookies } from 'universal-cookie';
import { CookieType } from '../data-types/cookies/cookiesEnums';
import { setIsBetkwiffDomain, setIsCasinoDomain, setWebAppOrigin } from '../state/actions';
import { getCookie } from './cookies';
import { getGeoLocation } from './i18n';
import { tryToInitiatePostHog } from './posthog';
import { checkIfIsBetkwiffDomain, checkIfIsCasinoDomain, getWebAppOrigin } from './url';

export let cookies: Cookies;
export const POST_HOG_ENABLED = Boolean(JSON.parse(process.env.REACT_APP_POST_HOG_ENABLED || 'false'));
export const GOOGLE_TAG_MANAGER_API_KEY = process.env.REACT_APP_GOOGLE_TAG_MANAGER_API_KEY || '';

const importPolyFillScrollingLibrary = async () => {
  if (!('scrollBehavior' in document.documentElement.style)) {
    await import('scroll-behavior-polyfill');
  }
};

const tryToFetchGeoLocation = async () => {
  const userCountry = getCookie(CookieType.COUNTRY);
  if (!userCountry) {
    await getGeoLocation();
  }
};

export const initialise = async (): Promise<boolean> => {
  cookies = new Cookies();
  importPolyFillScrollingLibrary();

  await tryToFetchGeoLocation();

  const isCasinoDomain = checkIfIsCasinoDomain();
  const isBetkwiffDomain = checkIfIsBetkwiffDomain();
  const env = getWebAppOrigin(isBetkwiffDomain, isCasinoDomain);

  setIsCasinoDomain(isCasinoDomain);
  setIsBetkwiffDomain(isBetkwiffDomain);
  setWebAppOrigin(env);

  tryToInitiatePostHog();
  return true;
};
